<template>
  <div class="vehicleManagePage">
    <!-- <el-breadcrumb separator="/" style="margin: 10px 0px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>所有运单</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 表格区域 -->
    <div style="max-height:84.5vh">
      <!-- 温馨提示 -->
      <!-- <el-alert style="margin-bottom: 8px;" type="warning" description="待办理和已完成的所有运单，供筛选和下载" :closable="false">
      </el-alert> -->
      <div class="topbtns">
        <el-button icon="el-icon-download" type="primary" size="medium" @click="exportExcel">导出表格</el-button>
      </div>
      <el-form class="searchForm" :model="searchForm" ref="searchForm">
        <div style="display: flex; flex-wrap: wrap;">
          <div style="display: flex; flex-wrap: wrap;">
            <el-form-item label="司机手机号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.driverPhone"
                placeholder="请输入司机手机号">
              </el-input>
            </el-form-item>
          </div>
          <div style="display: flex; flex-wrap: wrap;">
            <el-form-item label="收款人手机号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.receivePhone"
                placeholder="请输入收款人手机号">
              </el-input>
            </el-form-item>

            <el-form-item label="场景类型：" prop="keyword" label-width="140px">

              <el-select @change="changeType" v-model="searchForm.type" placeholder="请选择场景类型" style="width: 180px">
                <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="keyword">
              <div style="display: flex;">
                <el-dropdown @command="handleCommandName" trigger="click" placement="bottom">
                  <span class="el-dropdown-link">
                    <div
                      style="width: 108px; text-align: right;padding-right: 12px; color: #606266; cursor: pointer;white-space: nowrap;">
                      {{enterpriseName}}<i style="margin-left: 2px;" class="el-icon-arrow-down">：</i></div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in nameList" :key="item.id">{{item.name}}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.companyName"
                  placeholder="请输入企业名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item label-width="10px">
              <div style="white-space:nowrap;">
                <el-button type="primary" size="medium" @click="search('searchForm')" icon="el-icon-search">搜索
                </el-button>
                <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm('searchForm')">清空
                </el-button>
                <el-button type="primary" size="medium" :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                  @click="isShowSelect">更多选项</el-button>

              </div>

              <!-- <div class="showSelect" @click="isShowSelect">{{showSelect ? '隐藏筛选框' : '显示筛选框'}}</div> -->
            </el-form-item>
          </div>
        </div>
        <!-- <el-form-item label="运单号：" prop="keyword" label-width="130px">
          <el-input v-model="searchForm.wayBillID" placeholder="请输入运单号">
          </el-input>
        </el-form-item> -->
        <el-collapse-transition>
          <div style="display: flex; flex-wrap: wrap;" v-show="showSelect">
            <el-form-item label="车牌号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.carNumber"
                placeholder="请输入车牌号">
              </el-input>
            </el-form-item>
            <el-form-item label="司机姓名：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.driverName"
                placeholder="请输入司机姓名">
              </el-input>
            </el-form-item>
            <el-form-item label="起始地：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.startAddress"
                placeholder="请输入起始地">
              </el-input>
            </el-form-item>
            <el-form-item label="到达地：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.arriveAddress"
                placeholder="请输入到达地">
              </el-input>
            </el-form-item>
            <el-form-item label="开票申请编号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.settleNumber"
                placeholder="请输入开票申请编号">
              </el-input>
            </el-form-item>

            <el-form-item label="进度状态：" prop="keyword" label-width="140px">
              <el-select @change="changeProgress" v-model="searchForm.status" placeholder="请选择进度状态"
                style="width: 180px">
                <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="" prop="keyword">
              <div style="display: flex;">
                <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
                  <span class="el-dropdown-link">
                    <div
                      style="width: 108px; text-align: right;padding-right: 12px; color: #606266; cursor: pointer;white-space: nowrap;">
                      {{timeName}}<i style="margin-left: 2px;" class="el-icon-arrow-down">：</i></div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in timeList" :key="item.id">{{item.name}}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-date-picker @change="search" v-model="searchForm.time" type="daterange" start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间" value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
              <!-- <div>签收时间：</div>
              <el-date-picker v-model="searchForm.time" type="datetimerange" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker> -->
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>
      <el-table :data="tableData" v-loading="loading" :header-cell-style="{color: '#666', background: '#f0f0f0'}">
        <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="服务商" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ImportTime" label="运单导入时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceApplyDatetime" label="开票申请时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayCompleteDatetime" label="确认支付时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ReceiptInvoiceTime" label="开票完成时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{scope.row.DriverPhone}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{scope.row.CarNumber}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LoadNetWeight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{scope.row.OriginAddress}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{scope.row.ArriveAddress}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="司机装货数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="司机运输单价（人民币）" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.DriverCarryPrice | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.CarriageTotalPrice | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;" v-if="searchForm.type == '01'">
              {{scope.row.ExpensesOfTaxation | formatMoney}}
            </div>
            <div style="color:#FF8B17;" v-if="searchForm.type == '06'">
              {{ scope.row.TecServiceFee | formatMoney }}
            </div>
            <div style="color:#FF8B17;" v-if="$entrustTypeIds.includes(searchForm.type)">
              {{scope.row.OriginalExpensesOfTaxation | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;" v-if="searchForm.type == '01' || searchForm.type == '06'">
              {{scope.row.TotalAmount | formatMoney}}
            </div>
            <div style="color:#FF8B17;" v-if="$entrustTypeIds.includes(searchForm.type)">
              {{scope.row.OriginalTotalAmount | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip v-if="$entrustTypeIds.includes(searchForm.type)" align="center"
          prop="EntrustedCollectionAmount" label="委托代征运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.EntrustedCollectionAmount | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip v-if="$entrustTypeIds.includes(searchForm.type)" align="center" prop="ExpensesOfTaxation"
          label="委托代征服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.ExpensesOfTaxation | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="$entrustTypeIds.includes(searchForm.type)" align="center" prop="TotalAmount" label="委托代征运单总金额" width="200"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.TotalAmount | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalTransCost" label="运输总成本" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.TotalTransCost | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TrailerNumber" label="挂车车牌" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="UnloadNetWeight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OwnerContactsName" label="货主联系人" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OwnerContactsPhone" label="货主联系人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarrierName" label="承运商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarrierContactsName" label="承运商联系人" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarrierContactsPhone" label="承运商联系人手机号码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarriagePrice" label="承运运输单价（人民币）" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.CarriagePrice | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="AllowLossRate" label="允许损耗率(‰)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DrawnUnit" label="提货单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DrawnContactsPhone" label="提货联系人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DrawnAddress" label="提货地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DrawnContactsName" label="提货联系人" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TakeGoodsUnit" label="卸货单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="UnloadGoodsContactsPhone" label="卸货联系人手机号码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="UnloadGoodsAddress" label="卸货地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="UnloadGoodsContactsName" label="卸货联系人" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignBillID" label="签收单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="HandworkBillID" label="手工单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BillDatetime" label="单据时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskStatus" label="运单状态" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.TaskStatus == 3 ? 'success' : 'danger'"> {{scope.row.TaskStatus == 3 ? '已开票' :
              '未开票'}}</el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>

    </div>

  </div>
</template>

<script>
import { getAllBillList, allBillDwon } from '@/api/waybill/otherAndAllBill/index'
import { getDataDict } from '@/api/common/common'
export default {
  data() {
    return {
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索表单
      searchForm: {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        driverName: '', // 司机姓名
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: '',// 时间
        type: '01', // 场景类型 默认为查找承运业务
        status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '', // 到达地
        settleNumber: '', // 结算单号
        supplierName: '', // 服务商名称
        DateType: 1, // 时间类型
        enterpriseId: 1, // 货主名称选择类型id
      },
      tableData: [], //表格数据
      sceneList: [], //场景类型
      statusList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '未完成流程'
        },
        {
          value: '2',
          label: '已完成流程'
        }
      ], // 进度状态
      // 是否禁用提交按钮
      showSubmit: false,
      loading: false,
      showSelect: false, // 控制是否显示筛选框
      // 时间选择列表
      timeList: [
        {
          id: 1,
          name: '签收时间',
        },
        {
          id: 2,
          name: '装货时间',
        },
        {
          id: 3,
          name: '运单导入时间',
        },
        {
          id: 4,
          name: '开票申请时间',
        },
        {
          id: 5,
          name: '确认支付时间',
        },
        {
          id: 6,
          name: '开票完成时间',
        }
      ],
      timeName: '签收时间',
      nameList: [
        {
          id: 1,
          name: '货主名称'
        },
        {
          id: 2,
          name: '服务商名称'
        },
      ], // 企业名称
      enterpriseName: '货主名称',
    }
  },
  watch: {
  },
  methods: {
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect
    },
    //搜索
    search(formName) {

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //搜索前初始化page
      this.pagination.page = 1
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        //SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,

      }
      getAllBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
      //   }
      //   })
    },
    //清空
    resetForm(formName) {
      this.loading = true

      this.searchForm = {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        driverName: '',
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: [],// 时间
        type: '01', // 场景类型 默认为查找承运业务
        status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '', // 到达地
        settleNumber: '', // 结算单号
        supplierName: '', // 服务商名称
        DateType: this.searchForm.DateType,
        enterpriseId: 1
      }
      this.enterpriseName = '全部'
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        // SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,
      }
      getAllBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //导出excel
    exportExcel() {
      let params = {
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        // SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,
      }
      allBillDwon(params).then(res => {
        window.location.href = res.data
      })
    },
    //tbale选中改变
    tableSelectionChange(e) {

    },
    // 切换场景类型
    changeType(val) {
      this.searchForm.type = val
      this.search()
    },
    // 切换进度状态
    changeProgress(val) {
      this.searchForm.status = val
      this.search()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        // SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId
      }
      getAllBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //获取所有运单列表
    getAllBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        // SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId
      }
      this.loading = true;
      getAllBillList(params).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      })
        .finally(() => {
          this.loading = false;
        })
    },
    //获取场景类型列表
    getSceneList() {
      getDataDict({ type: 27 }).then(res => {
        this.sceneList = res.patterSetInfo
      })
    },
    // 选择时间下拉菜单
    handleCommand(val) {
      this.timeName = val.name
      this.searchForm.DateType = val.id
      this.search()
    },
    // 货主名称下拉
    handleCommandName(val) {
      this.enterpriseName = val.name
      this.searchForm.enterpriseId = val.id
      this.search()
    }
  },
  created() {
    //获取全部运单列表
    this.getAllBillList()
    //获取场景类型列表
    this.getSceneList()
  },

}
</script>

<style>
.vehicleManagePage .el-dialog {
  top: -10%;
}
</style>

<style scoped lang="scss">
.vehicleManagePage {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .showSelect {
    line-height: 40px;
    height: 40px;
    margin-left: 20px;
    color: #606266;
    cursor: pointer;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>